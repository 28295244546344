import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HomeComponent, SplashComponent, ContactComponent } from '.';
import { ContentComponent } from './content/content.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        SharedModule.forRoot(),
    ],
    declarations: [
        HomeComponent,
        SplashComponent,
        ContactComponent,
        ContentComponent
    ],
    exports: [
        HomeComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class HomeModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: HomeModule
        }
    }
}