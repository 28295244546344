import { NgModule, ModuleWithProviders } from "@angular/core";
import { SharedModule } from '../shared/shared.module';
import { DonateComponent } from '.';
import { SplashComponent } from './splash/splash.component';
import { ContentComponent } from './content/content.component';

@NgModule({
    imports: [
        SharedModule.forRoot()
    ], 
    declarations: [
        DonateComponent,
        SplashComponent,
        ContentComponent
    ], 
    exports: [
        DonateComponent
    ]
})

export class DonateModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DonateModule
        }
    }
}