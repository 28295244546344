import { SharedModule } from '../shared/shared.module';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AboutComponent, SplashComponent, ContentComponent, TeamComponent } from '.';

 @NgModule({
     imports: [
         SharedModule.forRoot()
     ], 
     declarations: [
         AboutComponent,
         SplashComponent,
         ContentComponent,
         TeamComponent
     ], 
     exports: [
         AboutComponent
     ], 
     schemas: [CUSTOM_ELEMENTS_SCHEMA]
 })

 export class AboutModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AboutModule
        }
    }
 }