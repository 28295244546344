import { NgModule, ModuleWithProviders } from "@angular/core";
import { SharedModule } from '../shared/shared.module';
import { TestimonialsComponent, SplashComponent, ContentComponent } from '.';

@NgModule({
    imports: [
        SharedModule.forRoot()
    ],
    declarations: [
        TestimonialsComponent,
        SplashComponent,
        ContentComponent
    ],
    exports: [
        TestimonialsComponent
    ]
})

export class TestimonialsModule { 
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: TestimonialsModule
        }
    }
}