// modules
export * from './home/home.module'; 
export * from './about/about.module';
export * from './projects/projects.module';
export * from './testimonials/testimonials.module'
export * from './contact/contact.module';
export * from './donate/donate.module'; 

//components 
export * from './home/home.component';
export * from './about/about.component';
export * from './donate/donate.component';
export * from './contact/contact.component'; 
export * from './projects/projects.component'; 
export * from './testimonials/testimonials.component';
export * from './donate/donate.component'; 