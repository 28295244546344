import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageFormData, INITIAL_FORMDATA_MODEL } from '../../shared/models/MessageFormData';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';
import { EmailMessage } from 'src/app/shared/models/EmailMessage';
import { EmailSuccess } from 'src/app/shared/models/EmailSuccess';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from '../../shared/block-ui';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;

  form: FormGroup;
  formData: MessageFormData;
  isValid = true;
  submitted = false;
  success = false;
  showInvalid = false;

  submitting = false;
  url = 'https://crert-api.azurewebsites.net/api/';
  canConnect = true;

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get message(): AbstractControl {
    return this.form.get('message');
  }

  constructor(
    private readonly fb: FormBuilder,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.formData = INITIAL_FORMDATA_MODEL;
    this.form = new FormGroup({});
    this.initializeForm();
  }

  private initializeForm(): void {
    try {
      this.form = this.fb.group({});
      this.addNameControl(),
        this.addEmailControl(),
        this.addMessageControl()
    } catch (err) {
      console.log(err);
    }
  }

  private addNameControl(): void {
    try {
      this.form.addControl(
        "name",
        new FormControl(
          this.formData.name,
          [
            Validators.required,
          ]
        )
      )
    } catch (err) {
      console.log(err);
    }
  }

  private addEmailControl(): void {
    try {
      this.form.addControl(
        'email',
        new FormControl(
          this.formData.email,
          [
            Validators.required,
            Validators.email,
          ]
        )
      )
    } catch (err) {
      console.log(err);
    }
  }

  private addMessageControl(): void {
    try {
      this.form.addControl(
        'message',
        new FormControl(
          this.formData.email,
          [
            Validators.required,
          ]
        )
      )
    } catch (err) {
      console.log(err);
    }
  }

  private constructFormData(): MessageFormData {

    const data: MessageFormData = {
      name: this.name.value,
      email: this.email.value,
      message: this.message.value
    };

    return data;
  }

  private constructEmailData(message: MessageFormData): EmailMessage {
    const data: EmailMessage = {
      ToAddress: message.email,
      FromAddress: 'info@theysmilefoundation.org',
      TemplateName: 'TheySmile-Response',
      Name: message.name,
      Message: message.message
    };
    
    return data; 
  }

  submitMessage() {
    try {
      this.blockUI.start(); 

      this.submitted = true;
      this.submitting = true;

      if (this.form.invalid) {
        this.isValid = false;
        this.submitting = false;
        this.success = false;
        this.showInvalid = true;
        this.blockUI.stop();
        return false;
      }

      let data = this.constructFormData();
      this.createMessage(data); 
    } catch (err) {
      console.log(err);
    }
  }

  createMessage(data: MessageFormData) {
    try {
      const message = this.constructEmailData(data); 
      this.sendEmail(message); 
    } catch (err) {
      console.log(err); 
    }
  }

  private sendEmail(emailMessage: EmailMessage) {
    
    try {
      const templateUrl = this.url + Constants.sendEmailTemplate;
      const emailUrl = this.url + Constants.sendEmail;
      this.http.post<{ data: EmailSuccess }>(templateUrl, emailMessage).subscribe((x) => {
        if (x.data.messageSent) {
          this.http.post<{ data: EmailSuccess }>(emailUrl, emailMessage).subscribe((y) => {
            if (y.data.messageSent) {
              this.resetForm(); 
            }
          }); 
        }
      }); 
      
    } catch (err) {
      console.log(err); 
    }
  }

  private resetForm() {
    this.submitted = false;
    this.isValid = true;
    this.success = true;
    this.showInvalid = false;
    this.form.reset();
    this.blockUI.stop();
  }
}
