export interface MessageFormData {
    name: string,
    email: string,
    message: string
}

export const INITIAL_FORMDATA_MODEL: MessageFormData ={
    name: '', 
    email: '', 
    message: ''
}