import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { HomeModule, AboutModule, ProjectsModule, TestimonialsModule, ContactModule } from '.';
import { AppRoutingModule } from './app-routing.module';
import { DonateModule } from './donate/donate.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule.forRoot(),
    AboutModule.forRoot(), 
    ProjectsModule.forRoot(), 
    TestimonialsModule.forRoot(), 
    ContactModule.forRoot(), 
    DonateModule.forRoot()
  ],
  bootstrap: [AppComponent], 
  providers: [{provide: APP_BASE_HREF, useValue : '/' }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
