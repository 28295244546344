import { NgModule, ModuleWithProviders } from "@angular/core";
import { SharedModule } from '../shared/shared.module';
import { ContactComponent, SplashComponent, ContentComponent } from '.';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        SharedModule.forRoot()
    ],
    declarations: [
        ContactComponent, 
        SplashComponent, 
        ContentComponent
    ], 
    exports: [
        ContactComponent
    ]
})

export class ContactModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ContactModule
        }
    }
}