import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ProjectsComponent, SplashComponent } from '.';
import { SharedModule } from '../shared/shared.module';
import { ContentComponent } from './content/content.component';

@NgModule({
    imports: [
        SharedModule.forRoot()
    ], 
    declarations: [
        ProjectsComponent,
        SplashComponent,
        ContentComponent 
    ], 
    exports: [
        ProjectsComponent
    ], 
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ProjectsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ProjectsModule
        }
    }
}