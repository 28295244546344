import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input('page') page: string; 

  isHomePage = true; 
  isAboutPage = false; 
  isProjectPage = false; 
  isTestimonialPage = false; 
  isContactPage = false; 
  isDonatePage = false; 

  constructor() { }

  ngOnInit() {
    this.isHomePage = this.page == 'home'; 
    this.isAboutPage = this.page == 'about'; 
    this.isProjectPage = this.page == 'project'; 
    this.isTestimonialPage = this.page == 'testimonial'; 
    this.isContactPage = this.page == 'contact'; 
    this.isDonatePage = this.page == 'donate'; 
  }

}
