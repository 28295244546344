import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FooterComponent, MainMenuComponent, TopbarComponent, MobileMenuComponent } from '.';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BlockUIModule, BlockUIComponent } from 'ng-block-ui';
import { BlockTemplateComponent } from './block-ui';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule, 
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        })
    ],
    declarations: [
        FooterComponent,
        MainMenuComponent,
        TopbarComponent,
        MobileMenuComponent, 
        BlockTemplateComponent
    ],
    entryComponents: [
        BlockTemplateComponent
    ],
    exports: [
        FooterComponent,
        MainMenuComponent,
        TopbarComponent,
        MobileMenuComponent,
        FormsModule,
        ReactiveFormsModule, 
        BlockUIModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        }
    }
}