import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent, AboutComponent, ProjectsComponent, TestimonialsComponent, ContactComponent, DonateComponent } from '.';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'home', pathMatch: 'full', component: HomeComponent },
  { path: 'about', pathMatch: 'full', component: AboutComponent },
  { path: 'projects', pathMatch: 'full', component: ProjectsComponent },
  { path: 'testimonials', pathMatch: 'full', component: TestimonialsComponent },
  { path: 'contact', pathMatch: 'full', component: ContactComponent },
  { path: 'donate', pathMatch: 'full', component: DonateComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
