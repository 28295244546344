import { Component } from '@angular/core';

@Component({
  selector: 'app-block-temp',
  styles: [`
    :host {
      text-align: center;
      color: #ffffff;
    }
  `],
  template: `
    <div class="block-ui-template">
        <img src='../../../assets/upload/loading.gif' />
        <br />
        <p>Sending...</p>
    </div>
  `
})
export class BlockTemplateComponent {
  message: any;
}
